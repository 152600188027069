import React from 'react';
import "typeface-montserrat-subrayada";
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../components/footer/footer'
import SEO from '../components/seo';
import CircleGeometry from 'cesium/Source/Core/CircleGeometry';

const HomePage = (props: any) => {
  const { data } = props;

////// パラメータ取得 /////////////////////////
const paramm = typeof window !== 'undefined' ? window.location.search : '';
const param = decodeURI(paramm)
console.log(param);
const query1 = param
  .slice(1)
  .split('&')
  .map((str) => [str.split('=')[0], str.split('=')[1]])
  .reduce((acc, a) => {
    acc[a[0]] = a[1];
    return acc;
  }, {} as QueryString);

console.log(query1["city"]);
//////////////////////// パラメータ取得 //////


if (query1["city"] !== undefined){
  if (typeof window !== 'undefined') {
    localStorage.setItem("cityset1", query1["city"]);
  }
}
else if (typeof window !== 'undefined') {
  if (localStorage.getItem("cityset1") == undefined){
    if (typeof window !== 'undefined') {
      localStorage.setItem("cityset1", "福岡県糸島市");
    }
  }
}

if (typeof window !== 'undefined') {
  if (localStorage.getItem("cityset2") == undefined){
    if (typeof window !== 'undefined') {
      localStorage.setItem("cityset2", "福岡県");
    }
  }
}





  return (
    <div>
      <SEO
        title="トップページ"
        description="全国の市町村データを３Dで可視化！誰でも，簡単に無料で使えるWeb GISです。人口や産業に関する1kmメッシュデータを地図上に三次元表示し，地域にあらわれる現象や課題を視覚的に捉えることができます。地理総合や地理探究の学習項目や，SDGｓの17の開発目標からテーマを選択できます。"
      />

      <div>
        <div style={{position:"relative",height:"100%",zIndex:1}}>
          <div style={{
              top: "0",
              left: "0",
              width: "auto",
              height: "auto",
              minWidth: "100%",
              minHeight: "100%",
              maxWidth: "inherit",
          }}>
          </div>
        </div>

        <div style={{borderColor:"5px", position:"absolute",margin:"30% 0 0 10vw", width:"20vw",zIndex:5}}>
          <a href="#" style={{display:"block",zIndex:5}}>

          </a>
        </div>

        <div>
          <div style={{position:"absolute",margin:"5% 0 0 5vw", zIndex:5}}>
          <a href="/themeset"><StaticImage style={{position:"absolute", width:"45vw"}} src="../images/top/logo_l2.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

          <div style={{position:"absolute",margin:"40% 0 0 5vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei1.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

          <div style={{position:"absolute",margin:"42% 0 0 16vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei2.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

          <div style={{position:"absolute",margin:"42% 0 0 27vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei3.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

        </div>
        <div>

<div style={{position:"absolute",margin:"5% 0 0 75vw", zIndex:5, width:"17vw"}}>
          <a href="/about/#t"><StaticImage src="../images/top/logo_b.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

<div style={{position:"absolute",margin:"5% 0 0 55vw", zIndex:5, width:"17vw"}}>
          <a href="/themeset"><StaticImage src="../images/top/logo_d.png" alt="地理学習のための地図と統計データのマッシュアップサイト" /></a>
          </div>

        </div>
      </div>

      <StaticImage src="../images/top/top_l5.png" alt="地理学習のための地図と統計データのマッシュアップサイト" width={2000}/>
      <Footer>
          Copyright &copy; {new Date().getFullYear()}
          <a href="https://www.teikokushoin.co.jp/"> 帝国書院</a>
          <span> ＆ </span>
          <a href="https://www.kashika.or.jp/geograph"> 一般社団法人 都市構造可視化推進機構</a>
        </Footer>
    </div>
  );
};

export default HomePage;

